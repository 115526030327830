.skills__list {
    max-width: 1000px;
    width: 95%;
    margin: 0 auto;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .skills__list-item {
    margin: 0.5em;
  }
  .skill__image{
    width:90px;
    height:90px;
    border: none;
    padding: 15px;
  }