.Profile_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 40px; 
    padding-bottom: 200px;
  }
  
  .profile {
    flex: 1;
    text-align: center;

  }

  .image:hover{
    box-shadow: var(--clr-pc) 0px 15px 15px 10px;
  }

  .visit{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image {
    border-radius: 15px;
    max-width: 100%;
    height: auto;
    transition: box-shadow 0.3s ease-in-out;
    margin-left: 40px;
  }

  .hire{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--clr--dot);
    padding-bottom: 55px;
  }

  .dot {
    height: 13px;
    width: 13px;
    background-color: var(--clr--dot);
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 1px;
    margin-right: 3px;
    margin-left: 3px;
  }
  .hire > h3{
    padding: 10px;
    border:solid 2px var(--clr-primary);
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;
  }
  .hire > h3:hover{
    box-shadow: var(--clr-pc) 0px 5px 5px 5px;
  }
  .details {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 20px; 
    margin-left: 20px;
  }
  
  .social-media {
    display: flex;
    gap: 20px; 
    align-items: center;
    justify-content: center;
  }
  
  .social-media a {
    color: var(--clr-primary);
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: var(--clr-primary-hover);
  }
  .description{
    text-align: center;
    width:400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 130px;
  }
  

  @media (max-width: 768px) {
    .Profile_section {
      flex-direction: column;
      gap: 20px; 
    }
  
    .profile, .details {
      flex: none;
      width: 100%;
    }
  }
  