.about {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
  }
  
  .about__name {
    color: var(--clr-primary);
  }
  
  .about__role {
    margin-top: 1.2em;
  }
  
  
  .about__desc {
    font-size: 1rem;
    max-width: 600px;
    text-align: center;
  }
  
  .about__desc,
  .about__contact {
    margin-top: 2.4em;
  }
  
  .about .link--icon {
    margin-right: 0.8em;
  }
  
  .about .btn--outline {
    margin-right: 1em;
  }

.rounded{
  margin-left:10px;
  border-radius: 5px;
}
  
  @media (max-width: 600px) {
    .app .about {
      align-items: flex-start;
      margin-top: 2em;
    }
  }