.certificate {
  width: 100%;
  height: 100vh;
  margin-top:-2px ;
}

.section__title {
  text-align: center;
  margin-bottom: 20px;
}

.certificates-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  cursor: pointer;
}

.certificate-item {
  position: relative;
  padding: 10px;
  width: 220px;
  height: 180px;
  box-shadow: var(--shadow);
  border-radius: 15px;
  border: 2px solid transparent;
  text-align: center;
  overflow: hidden;
  gap:15px;
  transition: transform 0.3s linear, border 0.3s linear, box-shadow 0.3s linear;
}

.certificate-item:before {
  content: '';
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.certificate-item:hover:before {
  right: 0;
}

.certificate-item:hover {
  transform: translateY(-10px);
  border: 2px solid var(--clr-primary);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 6px 0px;
}

.certificate-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.content-details {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.3s ease-in-out;
  border-radius: 10px;
}

.certificate-item:hover .content-details {
  top: 0;
}

.content-title {
  color: rgb(237, 249, 254);
  font-size: 1.5em;
  text-align: center;
  background-color: transparent;
}

/* Responsive styling */
@media (max-width: 600px) {
  .certificates-container {
    flex-direction: column;
    align-items: center;
  }

  .certificate-item {
    width: 80%;
  }
}
